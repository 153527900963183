//@ts-ignore
import MarkerClusterer from '@google/markerclusterer';
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { fonts, fontSize } from '../../themes';
import formatCurrency from '../../../utils/formatCurrency';


export interface Marker {
  caption?: {
    pricePerPiece?: string,
    status?: string,
    quantityAvailable?: number

  }
  icon?: string | {
    anchor: any;
    origin: any;
    scaledSize?: any;
    size?: any;
    url: string;
  };
  photo?: string;
  position: {
    lat: number;
    lng: number;
  };
  redirect?: string;
  subtitle?: string;
  title?: string;
}

export interface MapProps extends RouteComponentProps {
  mapMarkers?: Marker[];
  mapDefaultLat?: number;
  mapDefaultLng?: number;
  mapDefaultZoom?: number;
}


const Map = ({ history, mapMarkers, mapDefaultLat, mapDefaultLng, mapDefaultZoom }: MapProps) => {
  const mapDiv = useRef(null);
  const [map, setMap] = useState();
  const [clusterer, setClusterer] = useState<MarkerClusterer>();


  useEffect(() => {
    //@ts-ignore
    const newMap = new google.maps.Map(mapDiv.current, {
      center: { lat: mapDefaultLat ? mapDefaultLat : 51.477143, lng: mapDefaultLng ? mapDefaultLng : 4.335803 },
      zoom: mapDefaultZoom,
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "administrative.neighborhood",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ],
    });
    setMap(newMap);
    setClusterer(new MarkerClusterer(newMap, [], { imagePath: '/m' }));
  }, [mapDefaultLat, mapDefaultLng, mapDefaultZoom]);
  useEffect(() => {
    if (map && mapMarkers) {
      //@ts-ignore
      const infowindow = new google.maps.InfoWindow();
      const markers = mapMarkers.map(({ caption, photo, redirect, subtitle, ...marker }) => {
        //@ts-ignore
        const googleMarker = new google.maps.Marker({
          ...marker,
        });
        googleMarker.addListener('click', () => {
          const content = document.createElement('div');
          content.className = 'col-xs-12';
          content.innerHTML = `
            <span class="row center-xs" style="font-family:${fonts.primary};font-size:${fontSize.default};font-weight:bold;text-transform:uppercase;padding-top: 10px;">${marker.title || ''}</span>
            <span class="row center-xs" style="font-family:${fonts.primary};font-size:${fontSize.small};padding-top: 10px;">${subtitle || ''}</span>
            <img style="width: 200px; max-height:150px; object-fit: contain;padding-top: 10px;" src="${photo || '/no-image.png'}"/>
            <div style="padding-top: 10px;"></div>
            <div style="padding-top: 10px;"></div>
            <b style="font-weight: bold">${(caption && caption.status && caption.status === "niet verkocht") ? `Beschikbaar: ${caption.quantityAvailable} </b>` : `<b style="color:red">Niet beschikbaar</b>`}
            <span class="row center-xs" style="padding-top: 10px;">${(caption && caption.pricePerPiece && formatCurrency(caption.pricePerPiece)) || ''}</span>
            <div class="row center-xs" style="padding-top: 10px;">
              <button type="button" class="btn btn-secondary" style="width: 210px; border-radius: 11px;">Meer Info</a>
            </div>
            `;
          const button = content.getElementsByTagName('button')[0];
          if (redirect) {
            button.onclick = () => history.push(redirect);
          }
          infowindow.setContent(content);
          infowindow.setPosition(marker.position);
          infowindow.open(map);
        })
        return googleMarker;
      });
      clusterer?.clearMarkers();
      clusterer?.addMarkers(markers);
    }
  }, [map, mapMarkers, clusterer, history])
  return (
    <div style={{ width: '100%', height: '70vh' }} ref={mapDiv} />
  )
}



export default withRouter(Map);
