import React from 'react';
import { Input, InputProps } from '../../atoms/Input';
import { Label, LabelProps } from '../../atoms/Label';
import { palette } from '../../themes';

export interface InputLabelProps extends InputProps, LabelProps {
    inputLabelStyle?: any;
    inputError?: string;
    regRef?: any;
    shrink?: boolean;
}

const InputLabel = ({ inputError, inputLabelStyle, ...props }: InputLabelProps) => {
    return (
        <div>
            <Input {...props} />
            <Label {...props} labelStyle={{ color: palette.danger, fontSize: 12, textAlign: 'left', paddingBottom: '8px', ...inputLabelStyle }}>
                {inputError || ""}
            </Label>
        </div>
    )
};

export { InputLabel };

