import 'flexboxgrid';
import React, { CSSProperties } from 'react';

export interface RowProps {
  children?: any;
  rowStyle?: CSSProperties;
  rowOptions?: string;
  rowAlignItemsCenter?: boolean;
  rowNoMargin?: boolean;
}


const Row = ({ rowNoMargin, children, rowStyle, rowOptions, rowAlignItemsCenter }: RowProps) => {
  const alignItem = rowAlignItemsCenter ? { alignItems: 'center' } : {}
  const rowOpt = rowOptions ? `row ${rowOptions}` : `row`
  const rowMarg = rowNoMargin ? { margin: '0px' } : {}
  return (<div className={rowOpt} style={{ ...alignItem, ...rowMarg, ...rowStyle }}>{children}</div>)
};

export { Row };
