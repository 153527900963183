import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { ProductModel } from '../../../models/products';
import { firestore } from '../../../utils/firebase';
import { ProductScreen } from '../../organisms/ProductScreen';

const MainPage = () => {
    const [snapshot] = useCollection(firestore.collection("products"));


    const products = snapshot ? snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() as ProductModel })) : [];

    const sortedProducts = products.sort((a, b) => {
        const left = (a.data && a.data.creationDate && a.data.creationDate.toMillis()) || 0
        const right = (b.data && b.data.creationDate && b.data.creationDate.toMillis()) || 0
        return right - left
    })


    return (
        <ProductScreen products={sortedProducts} />
    )
}

export default MainPage