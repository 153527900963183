import React, { useEffect, useState } from 'react'
import { Document, ProductModel } from '../../../models/products'
import { Col } from '../../atoms/Col'
import { Dropdown } from '../../atoms/Dropdown'
import { Row } from '../../atoms/Row'
import { Space } from '../../atoms/Space'
import { LabelButtonPublic } from '../../molecules/LabelButtonPublic'
import { palette } from '../../themes'
import ProductCard from '../ProductCard'
import { ProductLegend } from '../ProductLegend'
import { ProductMap } from '../ProductMap'

interface ProductScreenProps {
    products: Document<ProductModel>[]
}

const ProductScreen = ({ products }: ProductScreenProps) => {
    const statusRecord = [{ name: "Beschikbaar", value: "niet verkocht" }, { name: "Niet beschikbaar", value: "verkocht" }]
    const categoryRec = products.map((item) => { return (item.data.category) })

    const noDublicateRecords = categoryRec.filter((item, index) => categoryRec.indexOf(item) === index)
    const categoryRecord = noDublicateRecords.map((data) => ({
        name: data || "",
        value: data || ""
    }))

    const [status, setStatus] = useState('niet verkocht')
    const [category, setCategory] = useState()
    const [filteredProducts, setFilteredProducts] = useState(products)
    useEffect(() => {
        setFilteredProducts(products.filter(p => !status || p.data.status === status).filter(p => !category || p.data.category === category))
    }, [category, products, status])
    return (
        <Row rowOptions="around-xs">
            <Col colSize="col-xs-5 col-xs-offset-5 end-xs">
                <LabelButtonPublic
                    className="btn"
                    labelButtonPublicText={"beheer mijn producten"}
                    block
                    labelButtonPublicStyle={{ width: '50%', marginTop: '20px', padding: 0 }}
                    onClick={(e) => {
                        e.preventDefault()
                        const _host = (window && window.location && window.location.hostname) || undefined
                        const _newHost = _host !== undefined && (_host.indexOf("oogstkaart") > -1) ? _host.replace("oogstkaart", "oogstadmin") : undefined
                        if (_newHost !== undefined) {
                            if (window.top) {
                                window.top.location.href = `https://${_newHost}`
                            } else {
                                window.location.href = `https://${_newHost}`
                            }
                        }
                    }}
                    labelStyle={{ color: palette.white, padding: 8 }}
                />
                <Space />
                <Space />
                <Space />
            </Col>


            <Col colSize="col-xs-10">
                <Row rowOptions="between-xs">
                    <Col colSize="col-xs-6 start-xs">
                        <Dropdown dropdownName="status" dropdownDefaultValue={status}
                            dropdownLabel="Status" dropdownRecords={statusRecord} dropdownOnChange={(e: any) => {
                                setStatus(e.target.value)
                            }
                            }></Dropdown>
                    </Col>

                    <Col colSize="col-xs-6 end-xs">
                        <Dropdown dropdownName="category" dropdownDefaultValue={category}
                            dropdownLabel="Category" dropdownRecords={categoryRecord} dropdownOnChange={
                                (e: any) => {
                                    const elem = e.target.value.trim()
                                    return (
                                        setCategory(elem)
                                    )
                                }
                            }></Dropdown>
                    </Col>
                </Row>
                <Space />
                <Space />
            </Col>




            <Col colSize="col-xs-10 center-xs" >
                <ProductMap products={filteredProducts} />
                <Space />
                <Space />
            </Col>
            <Col colSize="col-xs-12">
                <ProductLegend />
            </Col>
            <Col colSize="col-xs-12" >
                <ProductCard cards={filteredProducts} />
                <Space />
                <Space />
                <Space />
                <Space />
            </Col>
        </Row >
    )
}


export { ProductScreen };

