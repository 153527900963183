import React, { CSSProperties } from 'react';

export interface RowProps {
  children?: any;
  colStyle?: CSSProperties;
  colSize: string;
  colNoHorizontalPadding?: boolean;
}


const Col = ({ children, colStyle, colSize, colNoHorizontalPadding }: RowProps) => {
  const noPadding = colNoHorizontalPadding ? { paddingRight: 0, paddingLeft: 0 } : {}
  return (<div className={colSize} style={{ ...noPadding, ...colStyle }}>{children}</div>)
};

export { Col }