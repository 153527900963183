import React from 'react';
import { ProductModel } from '../../../models/products';
import { ButtonPublic } from '../../atoms/ButtonPublic';
import { Col } from '../../atoms/Col';
import { Form } from '../../atoms/Form';
import { Image } from '../../atoms/Image';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';
import { InputLabel } from '../../molecules/InputLabel';


export interface RegistratioFormProps {
    productData?: ProductModel | undefined;
    onRequestSendClick?: () => any;
    onRequestBackClick?: () => any;
    regRef?: (params: any) => void;
    errors?: any;
}


const ContactForm = ({ productData, regRef, errors, onRequestSendClick, onRequestBackClick }: RegistratioFormProps) => {
    return (
        <Form>
            <Row>
                <Col colSize="col-xs-12 center-xs">
                    <Space />
                    {productData && productData.articleName &&
                        <div>{productData.articleName}</div>
                    }
                    <Space />
                </Col>
                <Col colSize="col-xs-12 center-xs">
                    <Space />
                    {productData && productData.photos && productData.photos.length > 0 && productData.photos[0].url &&
                        <Image imageSrc={productData.photos[0].url} imageAlt="Image" imageStyle={{ maxHeight: '20vh' }} />
                    }
                    <Space />
                </Col>
            </Row>
            <Row rowAlignItemsCenter>
                <Col colSize="col-xs-12 center-xs">
                    <InputLabel name="name" regRef={regRef} inputError={(errors && errors.name && errors.name.message) || ""} inputFullWidth inputType="text" inputLabel="Naam" />
                    <InputLabel name="email" regRef={regRef} inputError={(errors && errors.email && errors.email.message) || ""} inputRequired inputFullWidth inputType="email" inputLabel="Email" />
                    <InputLabel name="adress" regRef={regRef} inputError={(errors && errors.adress && errors.adress.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Adres" />
                    <InputLabel name="gsm" regRef={regRef} inputError={(errors && errors.gsm && errors.gsm.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Gsm" />
                    <InputLabel name="extraInfo" regRef={regRef} inputError={(errors && errors.extraInfo && errors.extraInfo.message) || ""} inputFullWidth inputRequired={true} inputType="text" inputLabel="Informatie" />
                </Col>
            </Row>
            <Row>
                <Col colSize="col-xs-6 start-xs">
                    <Space />
                    <Space />
                    <ButtonPublic onClick={onRequestSendClick}>Verzenden</ButtonPublic>
                </Col>
                <Col colSize="col-xs-6 end-xs">
                    <Space />
                    <Space />
                    <ButtonPublic onClick={onRequestBackClick}>Terug</ButtonPublic>
                </Col>
            </Row>
            <Space />
            <Space />
        </Form>
    )
}


export { ContactForm };

