
import React from 'react'
import { Col } from '../../atoms/Col'
import { Row } from '../../atoms/Row'
import { palette } from '../../themes/index'

export interface MainTemplateProps {
  mainFooter?: JSX.Element,
  storybook?: boolean
  children?: any,
}

const MainTemplatex = ({ mainFooter, storybook, children, ...props
}: MainTemplateProps) => {
  return (
    <Row rowAlignItemsCenter>
      <Col colSize="col-xs-12" colStyle={{ background: storybook ? palette.primary : "" }}>
        <Row rowOptions={"around-xs"} rowAlignItemsCenter rowStyle={{ minHeight: '70vh' }}>
          <div className={"container-ex"}>
            <Col colSize="col-xs-12 ">
              {children || "Body"}
            </Col>
          </div>
        </Row>
      </Col>
    </Row>
  )
}

export default MainTemplatex
