import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { CardBody } from 'reactstrap';
import { Document, ProductModel } from '../../../models/products';
import formatCurrency from '../../../utils/formatCurrency';
import { ButtonPublic } from '../../atoms/ButtonPublic';
import { Col } from '../../atoms/Col';
import { Empty } from '../../atoms/Empty';
import { Image } from '../../atoms/Image';
import { Label } from '../../atoms/Label';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';


export interface ProductCardProps extends RouteComponentProps {
    cards: Document<ProductModel>[];
}

const ProductCard = ({ cards, history }: ProductCardProps) => {




    return (
        <Row rowAlignItemsCenter>
            {cards && cards.map(({ id, data }) => (
                <Col colSize="col-xs-4 center-xs">
                    <Row rowAlignItemsCenter rowOptions="center-xs">
                        <Col colSize="col-xs-8">
                            <Space />
                            <Space />
                            <Space />
                            <Space />
                            <Space />
                            <Space />
                            <CardBody style={{ boxShadow: "5px 6px 6px 4px  #cdcdcd", overflow: 'hidden' }} >
                                <Label labelSize="default" labelBold labelUppercase>{(data.articleName) || <Empty />}  </Label>
                                <Space />
                                <Label labelSize="small" >{(data.category) || <Empty />}</Label>
                                <Space />
                                <Row rowStyle={{ height: 200 }} rowOptions="center-xs">
                                    <Image imageStyle={{ maxWidth: 200, maxHeight: 200, width: '100%' }} imageSrc={(data.photos && data.photos.length && data.photos[0].url) || '/no-image.png'} imageAlt="img" />
                                </Row>
                                <br />
                                <Space />

                                <Space />

                                {(data && data.status && data.status === "niet verkocht") ? <b> Beschikbaar: {data.quantityAvailable} </b> : <b style={{ color: "red" }}> Niet beschikbaar</b>}

                                <br />
                                {(data && data.pricePerPiece && formatCurrency(data.pricePerPiece)) || <br />}
                                < Space />
                                <ButtonPublic type="button" onClick={() => { window.scrollTo(0, 0); history.push(`/products/${id}`) }} buttonPublicStyle={{ width: 210, borderRadius: "11px" }} buttonPrimary buttonTextColor="white">Meer Info</ButtonPublic>
                            </CardBody>
                        </Col>
                    </Row>
                </Col>))}
        </Row>
    )
}

export default withRouter(ProductCard);
