import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './App.css'
import ContactPage from './components/pages/ContactPage'
import MainPage from './components/pages/MainPage'
import ProductPage from './components/pages/ProductPage'
import MainTemplatex from './components/templates/MainTemplatex'

const App: React.FC = () => {
  return (
    <MainTemplatex>
      <BrowserRouter>
        <Switch>
          <Route exact path={"/"} component={MainPage} />
          <Route exact path="/products/:id" component={ProductPage} />
          <Route exact path="/contact/:id" component={ContactPage} />
        </Switch>
      </BrowserRouter>
    </MainTemplatex>
  )
}

export default App