import TextField from '@material-ui/core/TextField';
import React from 'react';
import { sizes } from '../../themes';
import { palette } from '../../themes/index';

export interface InputProps {
  inputSecondaryColor?: boolean;
  children?: any;
  inputType: "number" | "password" | "email" | "date" | "text" | "file";
  inputDisabled?: boolean;
  inputRequired?: boolean;
  inputMultiline?: boolean;
  inputRowsMax?: number;
  inputDefaultValue?: any;
  inputLabel?: any;
  inputHelperText?: string;
  inputFullWidth?: boolean;
  inputOnChange?: (e: any) => any;
  inputStyle?: any;
  name?: string;
  regRef?: any;
  shrink?: boolean;
}

const Input = ({ shrink, regRef, name, inputStyle, children, inputSecondaryColor, inputType, inputDisabled, inputRequired, inputMultiline, inputRowsMax, inputDefaultValue, inputLabel, inputHelperText, inputFullWidth, inputOnChange }: InputProps) => {

  const _shrink = inputType === "date" || shrink === true ? { shrink: true } : {}
  return (
    <TextField
      type={inputType}
      // onChange={inputOnChange}
      disabled={inputDisabled ? true : false}
      required={inputRequired ? true : false}
      multiline={inputMultiline ? true : false}
      rowsMax={inputMultiline ? inputRowsMax : 1}
      style={{ backgroundColor: inputSecondaryColor ? palette.secondary : palette.white, minWidth: sizes.fieldMinWidht, ...inputStyle }}
      // value={inputDefaultValue ? inputDefaultValue : ""}
      label={inputLabel}
      helperText={inputHelperText}
      fullWidth={inputFullWidth ? true : false}
      InputLabelProps={_shrink}
      InputProps={{ inputProps: { min: 0, max: 10 } }}
      name={name}
      inputRef={regRef}
    >
      {children}
    </TextField>)
};

export { Input };

