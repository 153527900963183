import React, { useMemo } from 'react';
import { Document, ProductModel } from '../../../models/products';
import Map from '../../atoms/Map';

interface IconSet {
    [key: string]: string;
}

const icons: IconSet = {
    constructieprofiel: '/B2-profiel.svg',
    deur: '/B2-deur.svg',
    geveldeel: '/B2-geveldeel.svg',
    'Jansen-profiel': '/B2-jansen-profiel.svg',
    overige: '/B2-overig.svg',
    raam: '/B2-raam.svg',
}

interface ProductMapProps {
    products: Document<ProductModel>[];
}

export const ProductMap = ({ products }: ProductMapProps) => {
    const markers = useMemo(() => products && products
        .filter(({ data: { latLng } }) => !!latLng)
        .map(({ id, data: { articleName, category, latLng, photos, pricePerPiece, status, quantityAvailable } }) => ({
            caption: { pricePerPiece, status, quantityAvailable },
            icon: category && {
                //@ts-ignore
                anchor: new google.maps.Point(0, 31),
                //@ts-ignore
                origin: new google.maps.Point(0, 0),
                //@ts-ignore
                scaledSize: new google.maps.Size(31, 31),
                //@ts-ignore
                size: new google.maps.Size(961.2, 945.4),
                url: icons[category],
            },
            photo: photos && photos.length ? photos[0].url : undefined,
            position: latLng!,
            redirect: `/products/${id}`,
            subtitle: category,
            title: articleName,
        })),
        [products]
    );
    return (
        <Map
            mapDefaultLat={51.477143}
            mapDefaultLng={4.335803}
            mapDefaultZoom={6.9}
            //@ts-ignore
            mapMarkers={markers}
        />
    )
}
