import React from 'react';
import { fonts, fontSize } from '../../themes/index';

export interface LabelProps {
  labelSize?: 'default' | "small" | "large" | "xlarge" | "xsmall"
  children?: any;
  labelBold?: boolean;
  labelUppercase?: boolean;
  labelStyle?: any;
  className?: string;
  blue?: boolean;
  labelOnClick?: () => any;
}


const Label = ({ blue, className, children, labelSize, labelBold, labelUppercase, labelStyle, labelOnClick }: LabelProps) => {
  let fSize = "";
  switch (labelSize) {
    case "xsmall":
      fSize = fontSize.xsmall
      break;
    case "small":
      fSize = fontSize.small
      break;
    case "large":
      fSize = fontSize.large
      break;
    case "xlarge":
      fSize = fontSize.xlarge
      break;
    default:
      fSize = fontSize.default;
      break;
  }


  return (<span className={blue ? "blue" : className}
    style={{
      fontFamily: fonts.primary, fontSize: fSize, fontWeight: labelBold ? "bold" : "normal",
      textTransform: labelUppercase ? "uppercase" : "none", ...labelStyle
    }} onClick={labelOnClick}>
    {children}
  </span>)
};

export { Label };

