import { History } from 'history';
import React from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import useForm from 'react-hook-form';
import { match } from 'react-router';
import * as yup from 'yup';
import { RequestListModel } from '../../../models/request';
import { firestore } from '../../../utils/firebase';
import { Col } from '../../atoms/Col';
import { Row } from '../../atoms/Row';
import confirm from '../../organisms/Confirm';
import { ContactForm } from '../../organisms/ContactForm';

interface ContactPageProps {
    history?: History,
    match?: match<{ id: string }>
}


const ContactPage = ({ match, history }: ContactPageProps) => {

    const path = `/products/${match && match.params.id}`

    const [snapshot] = useDocument(firestore.doc(path));

    //validation scheme
    const RegistrationValidationScheme = yup.object().shape({
        name: yup.string().required("veld is verplicht"),
        email: yup.string().required("veld is verplicht").email("veld moet email zijn"),
        adress: yup.string().required("veld is verplicht"),
        gsm: yup.string().required("veld is verplicht"),
        extraInfo: yup.string().required("veld is verplicht")
    });

    //form hook
    const { register, watch, errors, triggerValidation } = useForm({
        mode: "onBlur",
        validationSchema: RegistrationValidationScheme
    })

    //save 
    const handleSave = async () => {
        const data: RequestListModel = {
            ...watch(),
            productId: match && match.params.id,
            requestedOn: new Date()
        }
        //console.log(data, "data")
        await firestore.collection("requests").add(data);


        confirm("Je bericht is verzonden").then(async (resOk: any) => {
            history && history.push(path)
        },
            (closeRes: any) => {
                history && history.push(path)
            })
    }


    return (
        <Row rowOptions={"center-xs"}>
            <Col colSize={"col-xs-7"}>
                <ContactForm regRef={register} errors={errors} onRequestSendClick={
                    async () => {
                        const validated = await triggerValidation()
                        if (validated === true && match && match.params.id) {
                            handleSave()
                        }
                    }}
                    onRequestBackClick={() => {
                        history && history.push(path)
                    }}
                    productData={(snapshot && snapshot.data()) || undefined}
                />
            </Col>

        </Row>

    )
}

export default ContactPage