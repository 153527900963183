import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import React from 'react';
import { sizes } from '../../themes';
import { fonts } from '../../themes/index';


export interface DropDownRecord {
  name: string;
  value: string;
}


export interface DropDownProps {
  dropdownDefaultValue?: any;
  dropdownRecords?: DropDownRecord[];
  dropdownDisplayEmpty?: boolean;
  dropdownStyle?: any;
  dropdownOnChange?: (e: any) => any;
  dropdownLabel?: string;
  dropdownFullWidth?: boolean;
  dropdownName?: string;
  disabledText?: string;
  regRef?: any;
}



const Dropdown = ({ regRef, disabledText, dropdownFullWidth, dropdownLabel, dropdownDefaultValue, dropdownRecords, dropdownDisplayEmpty, dropdownStyle, dropdownOnChange, dropdownName, ...props }: DropDownProps) => {
  return (
    <FormControl fullWidth={dropdownFullWidth ? true : false}>
      <InputLabel>{dropdownLabel}</InputLabel>
      <Select
        name={dropdownName}
        inputRef={regRef}
        autoWidth
        style={{ fontFamily: fonts.primary, minWidth: sizes.fieldMinWidht, textAlign: 'left', ...dropdownStyle }}
        displayEmpty={dropdownDisplayEmpty ? true : false}
        value={dropdownDefaultValue ? dropdownDefaultValue : ""}
        onChange={(e: any) => {
          dropdownOnChange && dropdownOnChange(e)
        }}
      >
        <MenuItem value="" style={{ minHeight: '40px' }} />
        {dropdownRecords && dropdownRecords.map((record) =>
          <MenuItem key={record.value}
            value={record.value}>{record.name}</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export { Dropdown };

