import React, { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { match } from 'react-router';
import formatCurrency from '../../../utils/formatCurrency';
import { Col } from '../../atoms/Col';
import { Image } from '../../atoms/Image';
import { Label } from '../../atoms/Label';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';
import { IconLabel } from '../../molecules/IconLabel';
import { LabelButtonPublic } from '../../molecules/LabelButtonPublic';
import { ProductDescriptionLabel } from '../../molecules/ProductDescriptionLabel';
import { palette } from '../../themes';
import { ProductMap } from '../ProductMap';
import { ProductModel } from './../../../models/products/index';
import './ProductDetail.css';

export interface ProductModelProps {
  verkocht?: boolean;
  product?: ProductModel,
  match?: match<{ id: string }>
  onClickContact: () => any;
}


const ProductDetail = ({ onClickContact, product, verkocht, match }: ProductModelProps) => {
  const id = match && match.params.id

  console.log(id, "id")

  const mapProducts = useMemo(() => product ? [{
    id,
    data: product,
  }] : [], [product, id]);





  return (
    <Row rowOptions="around-xs" rowStyle={{ background: palette.white }}>

      {/* 1 - FOTO EN BTN*/}
      <Col colSize="col-xs-12" colStyle={{ background: "#f0f2f5" }}>
        <Col colSize="col-xs-12">
          <Row rowOptions={'center-xs'}>
            <Col colSize="col-xs-12" colStyle={{ padding: 30 }}>
              <Label labelBold className="title" labelStyle={{ fontSize: 27 }} > {(product && product.articleName) || ""} </Label>
            </Col>
          </Row>
        </Col>
        {/* slider */}
        <Row rowOptions="center-xs">
          <Col colSize="col-xs-8">
            <Carousel dynamicHeight={false} showThumbs={false} showStatus={false}>

              {product && product.photos && product.photos.map(item => (

                <div style={{
                  height: '600px',
                  position: 'relative',
                }}>

                  <div style={{
                    margin: 0,
                    width: '100%',
                    maxHeight: '100%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}>
                    <img src={item.url} alt="" style={{
                      width: '100%',
                      maxHeight: '600px',
                      objectFit: 'contain'
                    }} />
                  </div>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>

        <Col colSize="col-xs-12">
          {verkocht ?

            <Col colSize="col-xs-12 center-xs">
              <Space spaceBottom />
              <ProductDescriptionLabel
                productDescriptionText={(product && product.status) || ""}
                labelUppercase
                secondLabelStyle={{ color: "red", fontWeight: 'bold' }}
              />
            </Col>
            : ""
          }
          <Space spaceBottom />
          <Space />
          <Row rowOptions={'center-xs'}>
            <ProductDescriptionLabel
              productLabelText="prijs per stuk"
              productDescriptionText={formatCurrency(product && product.pricePerPiece) || "Prijs overeen te komen"}
              secondLabelStyle={{ fontWeight: 600, fontSize: 23 }}
              labelUppercase
            />
          </Row>

          <Space spaceBottom />
          <Row rowOptions={'center-xs'}>
            <ProductDescriptionLabel
              productLabelText="aantal beschikbaar"
              productDescriptionText={(product && product.status && product.status === "niet verkocht" && product.quantityAvailable) || "Niet beschikbaar"}
              secondLabelStyle={{ fontWeight: 600, fontSize: 23 }}
              labelUppercase
            />
          </Row>
        </Col>

        <Col colSize="col-xs-12 center-xs">
          <Row rowOptions={'center-xs'} >
            <Space />
            <LabelButtonPublic
              className="btn"
              labelButtonPublicText={"Ik ben geïnteresseerd in dit product"}
              block
              buttonPublicStyle={{ letterSpacing: 2 }}
              labelButtonPublicStyle={{ width: '50%', marginTop: '20px', padding: 0 }}
              disabled={verkocht ? true : false}
              onClick={onClickContact}
              labelStyle={{ color: palette.white, padding: 8 }}
            />
            <Space spaceBottomBig />
          </Row>
        </Col>
        <Space spaceBottom />
        <Col colSize="col-xs-12 center-xs">
          <Row rowOptions={'center-xs'} >
            <a href="/" >TERUG NAAR ALLE PRODUCTEN</a>
          </Row>
        </Col>

        <Space spaceBottom />

      </Col>

      <Col colSize="col-xs-12">
        {/* 2 - ALGEMENE INFO  */}
        <Row rowOptions={'center-xs'} >
          <Col colSize="col-xs-7 center-xs" >
            <Row rowOptions={'center-xs'} >
              <Col colSize="col-xs-12 center-xs" >
                <Space spaceTop />
                <Space spaceTop />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="categorie" productDescriptionText={(product && product.category) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="serie" productDescriptionText={(product && product.serie) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="transport" productDescriptionText={<IconLabel imageSrc="/transportIcon.svg" imageAlt="car-icon" iconLabelValue={(product && product.transportation ? "ja" : "nee") || ""} imageWidth="27px" />} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="beschikbaar vanaf" productDescriptionText={<IconLabel imageSrc="/calendarIcon.svg" imageAlt="calendar-icon" iconLabelValue={(product && product.availableFrom) || ""} imageWidth="20px" />} labelUppercase />
                <Space spaceBottomBig />
                <ProductDescriptionLabel
                  myFirstColOptions={"col-xs-0 start-xs"} mySecondColOptions={"col-xs-12 start-xs"}
                  tableProduct
                  productDescriptionText={(product && product.articleDescription) || ""}
                  labelUppercase
                  secondLabelStyle={{ textAlign: "justify" }}
                />
                <Space spaceBottomBig />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="gewicht" productDescriptionText={(product && product.weight) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="breedte" productDescriptionText={(product && product.width) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="hoogte" productDescriptionText={(product && product.height) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="lengte" productDescriptionText={(product && product.length) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="materiaal" productDescriptionText={(product && product.material) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="kleur" productDescriptionText={(product && product.color) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="brandwerend" productDescriptionText={(product && product.fireResistant) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="ral" productDescriptionText={(product && product.ral) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="u-waarde" productDescriptionText={(product && product.uValue) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="glas" productDescriptionText={(product && product.glass) || ""} labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="files"
                  productDescriptionText={
                    <Row rowOptions="start-xs">
                      {product && product.files && product.files.map(file => (
                        <Col colSize={"col-xs-2"} colStyle={{ marginLeft: "10px" }}>
                          <a href={file.url} target="blank" title={(file && file.fileName) || ""}><Image imageSrc="/adobe-pdf-icon.svg" imageAlt="file" imageWidth={'25px'} /></a>
                        </Col>
                      ))}

                    </Row>
                  } labelUppercase />
                <Space />
                <ProductDescriptionLabel tableProduct myFirstColOptions={"col-xs-4 start-xs"} mySecondColOptions={"col-xs-8 start-xs"} productLabelText="locatie" productDescriptionText={(product && product.location) || ""} labelUppercase />
                <Space />
                {product && product.latLng && <ProductMap products={mapProducts} />}
                <Space spaceBottomBig />
              </Col>
            </Row>
          </Col>
        </Row>

      </Col>

    </Row >
  )
}

export { ProductDetail };

