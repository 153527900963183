import React from 'react';
import { Image, ImageProps } from '../../atoms/Image';
import { Label, LabelProps } from '../../atoms/Label';
import { Col } from '../../atoms/Col';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';


export interface IconLabelProps extends ImageProps, LabelProps {
    iconLabelTitle?: string;
    iconLabelValue?: any;
    iconLabelStyle?: any;
    column?: boolean;
}

const IconLabel = ({ iconLabelTitle, iconLabelStyle, iconLabelValue, column, ...props }: IconLabelProps) => {
    return (
        <span style={iconLabelStyle}>
            {column ?
                <Label
                    {...props} >
                    <Col colSize="col-xs-12 center-xs" colStyle={{ background: "red", height: 15 }}>
                        <Label labelStyle={{ paddingLeft:  '5px'  }} {...props} >{iconLabelTitle}</Label>
                    </Col>
                    <Row>
                        <Col colSize="col-xs-6 end-xs" >
                            <Image {...props} />
                        </Col>
                        <Col colSize="col-xs-6 start-xs" >
                            <Label {...props} >{iconLabelValue}</Label>
                        </Col>
                    </Row>
                </Label>

                :
                <Col colSize="col-xs-12 start-xs">
                    <Row >
                        <Image {...props} />
                        <Space spaceRight />
                        <Label labelStyle={{ paddingLeft: '5px' }} {...props} >{iconLabelTitle || iconLabelValue}</Label>
                    </Row>
                </Col>
            }

        </span>
    )
};

export { IconLabel }