import React from 'react';

const Empty = () => {
  return (<div style={{
    height: '1.25em',
    lineHeight: '1.25'
  }}></div>)
};

export { Empty };

