
import React from 'react';
import { ButtonPublic, ButtonPublicProps } from '../../atoms/ButtonPublic';
import { Label, LabelProps } from '../../atoms/Label';


export interface LabelButtonProps extends ButtonPublicProps, LabelProps {
    labelButtonPublicText?: string;
    labelButtonPublicStyle?: any;
    className?: string;
}

const LabelButtonPublic = ({ className, labelButtonPublicStyle, labelButtonPublicText, ...props }: LabelButtonProps) => {
    return (
        <div className={className} style={labelButtonPublicStyle}>
            <ButtonPublic {...props}><Label {...props}>{labelButtonPublicText}</Label></ButtonPublic>
        </div>
    )
};

export { LabelButtonPublic };

