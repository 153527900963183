import React from 'react';
import { Col } from '../../atoms/Col';
import { Image } from '../../atoms/Image';
import { Label } from '../../atoms/Label';
import { Row } from '../../atoms/Row';



const ProductLegend = () => {
    return (
        <Row rowOptions={"around-xs"}>
            <Col colSize="col-xs-1">
                <Row rowAlignItemsCenter>
                    <Col colSize="col-xs-6 end-xs">
                        <Image imageWidth="100%" imageSrc="/B2-deur.svg" imageAlt="door" />
                    </Col>

                    <Col colSize="col-xs-6 start-xs">
                        <Label labelStyle={{ marginBottom: 10 }} labelSize={"default"}>Deur</Label>
                    </Col>
                </Row>
            </Col>


            <Col colSize="col-xs-1">
                <Row rowAlignItemsCenter>
                    <Col colSize="col-xs-6 end-xs">
                        <Image imageWidth="100%" imageSrc="/B2-raam.svg" imageAlt="window" />
                    </Col>

                    <Col colSize="col-xs-6 start-xs">
                        <Label labelStyle={{ marginBottom: 10 }} labelSize={"default"}>Raam</Label>
                    </Col>
                </Row>
            </Col>

            <Col colSize="col-xs-1">
                <Row rowAlignItemsCenter>
                    <Col colSize="col-xs-6 end-xs">
                        <Image imageWidth="100%" imageSrc="/B2-profiel.svg" imageAlt="framework" />
                    </Col>

                    <Col colSize="col-xs-6 start-xs">
                        <Label labelStyle={{ marginBottom: 10 }} labelSize={"default"}>Profiel</Label>
                    </Col>
                </Row>
            </Col>

            <Col colSize="col-xs-1">
                <Row rowAlignItemsCenter>
                    <Col colSize="col-xs-6 end-xs">
                        <Image imageWidth="100%" imageSrc="/B2-jansen-profiel.svg" imageAlt="filledwindow" />
                    </Col>

                    <Col colSize="col-xs-6 start-xs">
                        <Label labelStyle={{ marginBottom: 10 }} labelSize={"default"}>JansenProfiel</Label>
                    </Col>
                </Row>
            </Col>

            <Col colSize="col-xs-1">
                <Row rowAlignItemsCenter>
                    <Col colSize="col-xs-6 end-xs">
                        <Image imageWidth="100%" imageSrc="/B2-geveldeel.svg" imageAlt="wall" />
                    </Col>

                    <Col colSize="col-xs-6 start-xs">
                        <Label labelStyle={{ marginBottom: 10 }} labelSize={"default"}>Geveldeel</Label>
                    </Col>
                </Row>
            </Col>


            <Col colSize="col-xs-1">
                <Row rowAlignItemsCenter>
                    <Col colSize="col-xs-6 end-xs">
                        <Image imageWidth="100%" imageSrc="/B2-overig.svg" imageAlt="house" />
                    </Col>

                    <Col colSize="col-xs-6 start-xs">
                        <Label labelStyle={{ marginBottom: 10 }} labelSize={"default"}>Overige</Label>
                    </Col>
                </Row>
            </Col>
        </Row >


    )

}


export { ProductLegend };

