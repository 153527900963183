import React from 'react';
import { Label, LabelProps } from '../../atoms/Label';
import { palette } from '../../themes'
import { Row } from '../../atoms/Row';
import { Col } from '../../atoms/Col';
import { Space } from '../../atoms/Space';

export interface ProductDescriptionLabelProps extends LabelProps {
    productLabelText?: string;
    productDescriptionText?: any;
    productDescriptionLabelStyle?: any;
    myFirstColOptions?: any;
    mySecondColOptions?: any;
    labelUppercase?: boolean;
    tableProduct?: boolean;
    secondLabelStyle?: any;
    firstLabelStyle?: any;
}

const ProductDescriptionLabel = ({ firstLabelStyle, secondLabelStyle, tableProduct, labelUppercase, productDescriptionText, productDescriptionLabelStyle, productLabelText, myFirstColOptions, mySecondColOptions, ...props }: ProductDescriptionLabelProps) => {

    return (

        tableProduct ?
            <Col colSize={"col-xs-12"}>
                <Row rowOptions={"center-xs"} >
                    <Col colSize={myFirstColOptions ? myFirstColOptions : "col-xs-4 end-xs"}>
                        <Label blue {...props} labelStyle={{ color: palette.blue }} labelUppercase>
                            <span style={firstLabelStyle}>{productLabelText}</span>
                        </Label>
                    </Col>
                    <Space />

                    <Col colSize={mySecondColOptions ? mySecondColOptions : "col-xs-8 start-xs"} >
                        <Label {...props} labelStyle={{ color: palette.black }} >
                            <span style={secondLabelStyle}>{productDescriptionText}</span>
                        </Label>
                    </Col>
                </Row>
            </Col>
            :
            <Row rowOptions={"center-xs"} >
                <Label {...props} labelStyle={{ color: palette.blue }} labelUppercase>
                    <span style={firstLabelStyle}>{productLabelText}</span>
                </Label>
                <Space spaceRight />
                <Label {...props} labelStyle={{ color: palette.black, marginLeft: 10 }} >
                    <span style={secondLabelStyle}>{productDescriptionText}</span>
                </Label>
            </Row>
    )
};

export { ProductDescriptionLabel }