const fonts: { primary: string; secondary: string; pre: string; quote: string; } = {
  primary: 'Rubik, sans-serif',
  secondary: 'Roboto, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  quote: 'Georgia, serif',
}

const sizes: { maxWidth: string, fieldPadding: string, fieldMinWidht: string, fieldMargin30: string, fieldMargin20: string } = {
  maxWidth: '1100px',
  fieldPadding: '10px',
  fieldMargin20: '15px',
  fieldMargin30: '40px',
  fieldMinWidht: '300px'
}

const palette: { primary: string; secondary: string; danger: string; alert: string; success: string; white: string; grayscale: string; black: string; selected: string; lightBlack: string; grey: string; blue: string, lightgrey: string } = {
  primary: '#1976d2',
  secondary: '#E5E5E5',
  danger: '#FF0000',
  alert: '#ffecb3',
  success: '#c8e6c9',
  white: '#FFFFFF',
  grayscale: '#212121',
  black: '#000000',
  selected: "#CBDBE1",
  grey: "#A9A9A9",
  lightBlack: "#7f7f7f",
  blue: "#2182A0",
  lightgrey: '#F2F1F1'
}

const fontSize =
{
  xlarge: "50px",
  large: "30px",
  default: "20px",
  small: "17px",
  xsmall: "12px"
}


export { fonts, sizes, palette, fontSize }

