import React from 'react';

export interface FormProps {
    onSubmit?: (e: any) => any;
    children?: any;
}

const Form = ({ children, onSubmit }: FormProps) => {

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault()
    }

    return (
        <form onSubmit={onSubmit || handleSubmit}>
            {children}
        </form>
    )
}


export { Form };

