import { History } from 'history';
import React from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { match } from 'react-router';
import { firestore } from '../../../utils/firebase';
import { ProductDetail } from '../../organisms/ProductDetail';

interface ProducPageProps {
    history?: History,
    match?: match<{ id: string }>
}

const ProductPage = ({ match, history, ...props }: ProducPageProps) => {
    const path = `/products/${match && match.params.id}`
    const [snapshot] = useDocument(firestore.doc(path));

    const handleContact = () => {
        const _path = `/contact/` + (match && match.params.id)
        console.log(_path, "_path")
        history && history.push(_path)
    }

    return (
        <ProductDetail product={(snapshot && snapshot.data()) || []} onClickContact={handleContact} />
    )
}

export default ProductPage