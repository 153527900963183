import React from 'react';
import { sizes } from '../../themes/index';

export interface SpaceProps {
  spaceTop?: boolean;
  spaceRight?: boolean;
  spaceBottom?: boolean;
  spaceMargin?: boolean;
  spaceBottomBig?: boolean;
}

const Space = ({ spaceTop, spaceRight, spaceBottom, spaceBottomBig }: SpaceProps) => {
  return (<div style={{
    paddingTop: spaceTop ? sizes.fieldMargin20 : sizes.fieldPadding,
    marginRight: spaceRight ? sizes.fieldMargin20 : undefined,
    marginBottom: spaceBottomBig ? sizes.fieldMargin30 : undefined
  }}></div>)
};

export { Space };
