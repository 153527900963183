import React from 'react';

export interface ImageProps {
  imageSrc: string;
  imageAlt: string;
  imageWidth?: string,
  imageHeight?: string,
  imagePreview?: boolean;
  imageonLoad?: () => any;
  imageStyle?: any;
  iconImage?: boolean;
  maxWidth?: string;
  imageAutoWidth?: boolean;
}

const Image = ({ imageAutoWidth, maxWidth, imageSrc, imageAlt, imagePreview, imageStyle, imageonLoad, imageWidth, imageHeight, iconImage, ...props }: ImageProps) => {
  const _imageAutoWidth = imageAutoWidth ? '' : '200px'
  const iWidth = imageWidth ? imageWidth : _imageAutoWidth
  const iHeight = imageHeight ? imageHeight : '100px'
  return (
    <img src={imageSrc} alt={imageAlt}
      style={{ maxWidth: maxWidth, width: imagePreview ? '20px' : iconImage ? '30px' : iWidth, iHeight, objectFit: "contain", ...imageStyle }}
      onLoad={imageonLoad}
    />
  )
};

export { Image };

