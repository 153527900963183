
import React, { CSSProperties } from 'react';
import { Button } from 'reactstrap';

export interface ButtonPublicProps {
  children?: any;
  buttonPrimary?: boolean;
  buttonTextColor?: string;
  buttonPublicStyle?: CSSProperties;
  buttonTextExtraSpaceUnit?: string;
  onClick?: (e: any) => any;
  buttonNoRippleEffect?: boolean;
  buttonFullWidth?: boolean;
  buttonPublicColor?: string;
  active?: boolean;
  outline?: boolean;
  block?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

const ButtonPublic = ({ buttonFullWidth, buttonNoRippleEffect, children, buttonPrimary, buttonTextColor, buttonTextExtraSpaceUnit, onClick, buttonPublicStyle, buttonPublicColor, outline, active, block, disabled, type }: ButtonPublicProps) => {
  return (<Button type={type} style={buttonPublicStyle} outline={outline ? true : false} disabled={disabled ? true : false} active={active ? true : false} block={block ? true : false} color={buttonPublicColor} onClick={onClick}>{children}</Button >)
};

export { ButtonPublic };

